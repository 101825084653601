class Apps {
    constructor() {
        this.$selector = {
            extension: $('.js-apps'),
            search: $('.js-apps .apps-search'),
            filter: $('.js-apps .apps-filter')
        };

        if (this.$selector.extension.length > 0) {
            this.bindFilter();
            this.bindSearch();
            this.doSearch();
        }
    }

    bindFilter() {
        let instance = this;
        // @todo https://getbootstrap.com/docs/5.0/components/button-group/#checkbox-and-radio-button-groups
        let $buttons = this.$selector.filter.find('button');
        $buttons.on('click', function () {
            if ($(this).data('filter') === 'all') {
                $buttons.removeClass('active bg-secondary text-white');
                $(this).addClass('active bg-secondary text-white');
            } else {
                instance.$selector.filter.find('button[data-filter="all"]').removeClass('active bg-secondary text-white');
                $(this).toggleClass('active bg-secondary text-white');
            }
            if (instance.$selector.filter.find('button.active').length === 0) {
                instance.$selector.filter.find('button[data-filter="all"]').addClass('active bg-secondary text-white');
            }
            instance.doSearch();
        });
    };

    bindSearch() {
        let instance = this;
        this.$selector.search.on('changed', function () {
            instance.doSearch();
        });
        this.$selector.search.on('keyup', function () {
            instance.doSearch();
        });
    };

    doSearch() {
        let search = this.$selector.search.val();
        let $apps = this.$selector.extension.find('.app');
        let $rows = this.$selector.extension.find('.row');

        let isSearch = (search !== '');
        let isFilterAll = (this.$selector.filter.find('button.active[data-filter="all"]').length > 0);
        let isFilterSet = (this.$selector.filter.find('button.active').length > 0);
        let isFilter = (!isFilterAll && isFilterSet);
        if (isSearch || !(!isSearch && isFilterAll) || isFilter) {
            $apps.parent().addClass('d-none');
            if (isSearch) {
                $apps.filter('[data-id*="' + search.toLowerCase() + '"]').parent().removeClass('d-none');
                $apps.filter('[data-title*="' + search.toLowerCase() + '"]').parent().removeClass('d-none');
            }
            if (isFilterSet) {
                let $buttonsActive = this.$selector.filter.find('button.active').filter(':not([data-filter="all"])');
                if ($buttonsActive.length > 0) {
                    let $appsFound = $apps;
                    if (!(!isSearch || isFilterAll)) {
                        $appsFound = $apps.parent().filter(':not(.d-none)').find('.app');
                    }
                    $appsFound.parent().addClass('d-none');
                    $buttonsActive.each(function () {
                        let filter = $(this).data('filter');
                        $appsFound.find('.icon-app-small.icon-' + filter).closest('.app').parent().removeClass('d-none');
                    });
                }
            }
        } else {
            $apps.parent().removeClass('d-none');
        }

        // Hide rows
        $rows.each(function () {
            let $appsInRow = $(this).find('.app');
            let appsHidden = 0;
            $appsInRow.each(function () {
                if ($(this).parent().hasClass('d-none')) {
                    appsHidden++;
                }
            });
            if ($appsInRow.length === appsHidden) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        });
    };
}

jQuery(function ($) {
    new Apps();
});
