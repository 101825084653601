window.CYB = window.CYB || {};
window.CYB.Dice = function () {
    /**
     * Contains id/class selectors
     * @type {Object}
     */
    let $selector = {
        extension: $('.js-dice')
    };

    /**
     * Public function to initialize class
     */
    this.initialize = function () {
        let instance = this;
        if ($selector.extension.length > 0) {
            this.addBoard();
            bindButtonAddBoard(instance);
        }
    };

    /**
     * @param {jQuery} $board
     * @return {void}
     */
    this.initializeBoard = function ($board) {
        addRollButton($board);
        addDices($board);
        $board.slideDown('slow');
    };

    /**
     * @param {Dice} instance
     * @return {void}
     */
    let bindButtonAddBoard = function (instance) {
        $selector.extension.find('.button-add-board').click(function () {
            instance.addBoard();
        });
    };

    /**
     * @return {void}
     */
    this.addBoard = function () {
        let $container = $selector.extension.find('.container-boards');

        let newBoard = document.createElement('div');
        newBoard.className = 'board';
        newBoard.style.display = 'none';
        $container.append(newBoard);

        let newBoardDices = document.createElement('div');
        newBoardDices.className = 'board-dices';
        newBoard.append(newBoardDices);

        this.initializeBoard($(newBoard));
    };

    /**
     * @param {jQuery} $board
     * @return {void}
     */
    let addRollButton = function ($board) {
        let buttonRemoveBoard = document.createElement('button');
        buttonRemoveBoard.type = 'button';
        buttonRemoveBoard.className = 'btn btn-danger me-4';
        buttonRemoveBoard.innerHTML = '<span class="fa fa-trash"></span> Remove <span class="d-md-inline-block d-none">board</span>';
        buttonRemoveBoard.onclick = function() {
            $board.slideUp('slow', function() {
                this.remove();
            });
        };

        let buttonRoll = document.createElement('button');
        buttonRoll.type = 'button';
        buttonRoll.className = 'btn btn-primary';
        buttonRoll.innerHTML = '<span class="fa fa-play"></span> Roll <span class="d-md-inline-block d-none">the dices !</span>';
        buttonRoll.onclick = function() {
            diceRoll($board);
        };

        $board.append(document.createElement('br'));
        $board.append(buttonRemoveBoard);
        $board.append(buttonRoll);
    };

    /**
     * @param {jQuery} $board
     * @return {void}
     */
    let addDices = function ($board) {
        for (let i = 0; i < 5; i++) {
            addOneDice($board);
        }
        $board.find('.dice').fadeIn(600);
    };

    /**
     * @param {jQuery} $board
     * @return {void}
     */
    let addOneDice = function ($board) {
        let $dice = $('#dice').clone();
        let number = getRandomInt(1, 6);
        updateDicePins($dice, number);
        $dice.attr('title', number);
        $dice.hide();
        $dice.click(function() {
            diceChange($board, $(this));
        });
        $dice.appendTo($board.find('.board-dices'));
        calculateBoard($board);
    };

    /**
     * @param {jQuery} $dice
     * @param {int} value
     * @return {void}
     */
    let updateDicePins = function ($dice, value) {
        for (let i = 1; i <= 9; i++) {
            $dice.find('.pin' + i).css('display', 'none');
        }

        switch (value) {
            case 1:
                $dice.find('.pin5').css('display', 'block');
                break;
            case 2:
                if (getRandomInt(0, 1)) {
                    $dice.find('.pin7').css('display', 'block');
                    $dice.find('.pin3').css('display', 'block');
                } else {
                    $dice.find('.pin9').css('display', 'block');
                    $dice.find('.pin1').css('display', 'block');
                }
                break;
            case 3:
                if (getRandomInt(0, 1)) {
                    $dice.find('.pin7').css('display', 'block');
                    $dice.find('.pin3').css('display', 'block');
                } else {
                    $dice.find('.pin9').css('display', 'block');
                    $dice.find('.pin1').css('display', 'block');
                }
                $dice.find('.pin5').css('display', 'block');
                break;
            case 4:
                $dice.find('.pin9').css('display', 'block');
                $dice.find('.pin7').css('display', 'block');
                $dice.find('.pin3').css('display', 'block');
                $dice.find('.pin1').css('display', 'block');
                break;
            case 5:
                $dice.find('.pin9').css('display', 'block');
                $dice.find('.pin7').css('display', 'block');
                $dice.find('.pin5').css('display', 'block');
                $dice.find('.pin3').css('display', 'block');
                $dice.find('.pin1').css('display', 'block');
                break;
            case 6:
                if (getRandomInt(0, 1)) {
                    $dice.find('.pin6').css('display', 'block');
                    $dice.find('.pin4').css('display', 'block');
                } else {
                    $dice.find('.pin8').css('display', 'block');
                    $dice.find('.pin2').css('display', 'block');
                }
                $dice.find('.pin9').css('display', 'block');
                $dice.find('.pin7').css('display', 'block');
                $dice.find('.pin3').css('display', 'block');
                $dice.find('.pin1').css('display', 'block');
                break;
        }
    };

    /**
     * @param {jQuery} $board
     * @return {void}
     */
    let diceRoll = function ($board) {
        $board.find('.dice').fadeOut(600).delay(400, function() {
            $board.find('.board-dices').html('');
            addDices($board);
        });
    };

    /**
     * @param {jQuery} $board
     * @param {jQuery} $dice
     * @return {void}
     */
    let diceChange = function ($board, $dice) {
        $dice.fadeOut(400, function() {
            let number = getRandomInt(1, 6);
            updateDicePins($dice, number);
            $dice.attr('title', number);
            $dice.fadeIn(400);
            calculateBoard($board);
        });
    };

    /**
     * @param {jQuery} $board
     * @return {void}
     */
    let calculateBoard = function ($board) {
        let amount = 0;
        $board.find('.dice').each(function(index, obj) {
            amount += parseInt($(obj).attr('title'));
        });
        $board.attr('title', 'Total: ' + amount);
    };

    let getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
};

jQuery(function ($) {
    let dice = new window.CYB.Dice();
    dice.initialize();
});
