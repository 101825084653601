'use strict';

import '../css/app.scss';

async function loadJQuery() {
    const {default: jQuery} = await import(
        /* webpackChunkName: "jquery" */
        'jquery/dist/jquery'
    );
    return jQuery;
}
async function loadBootstrap() {
    const {default: Bootstrap} = await import(
        /* webpackChunkName: "bootstrap" */
        'bootstrap/dist/js/bootstrap'
    );
    return Bootstrap;
}

async function loadFontAwesome() {
    const {default: FontAwesome} = await import(
        /* webpackChunkName: "font-awesome" */
        './font-awesome.js'
    );
    return FontAwesome;
}

loadJQuery().then((jQuery) => {
    /* fix for https://github.com/symfony/webpack-encore/pull/54 */
    global.$ = global.jQuery = jQuery;

    loadBootstrap().then(() => {
    });

    loadFontAwesome().then(() => {
    });

    require('./javascript.js');
    require('./apps.js');
    require('./dice.js');
    require('./lotto.js');
});
