window.CYB = window.CYB || {};
window.CYB.Lotto = function () {
    /**
     * Contains id/class selectors
     * @type {Object}
     */
    var $selector = {
        extension: $('.js-lotto'),
        board: $('.js-lotto .board > div'),
        info: $('.js-lotto .info'),
        buttonRestart: $('.js-lotto .button-restart')
    };

    var listColor = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b'];
    var listColorSelected = ['#433B8F', '#0184CD', '#7CBC19', '#FFBC00', '#F07502', '#D00110'];
    var selected = [];

    /**
     * Public function to initialize class
     */
    this.initialize = function () {
        if ($selector.extension.length > 0) {
            initializeBoard();
            resetBoard();
        }
    };

    /**
     * @return {void}
     */
    var initializeBoard = function () {
        selected = [];
        var list = shuffleArray(generateLottoArray());

        $.each(list, function (index, value) {
            addCard(value);
        });
        changeInfo('Wähle 6 Kugeln');
    };

    var changeInfo = function (text) {
        $selector.info.fadeOut(400, function () {
            $(this).html(text).fadeIn(400);
        });
    };

    /**
     * @param {string} number
     * @return {void}
     */
    var addCard = function (number) {
        var $card = $('<div class="magic-card" data-number="' + number + '"><div></div><div>' + number + '</div></div>');
        $card.find('> div:first-child').css('background-color', getOneRandomFromArray(listColor));

        $card.click(function () {
            var max = 6;
            var amount = $selector.board.find('.flip').length;
            if (amount < max) {
                selected.push($(this).data('number'));
                $(this).toggleClass('flip').css('cursor', 'default').unbind('click');
                changeInfo('Wähle ' + (max - amount - 1) + ' Kugeln');
            }
            if ((amount + 1) >= max) {
                $selector.board.find('.magic-card').each(function () {
                    $(this).css('cursor', 'default').unbind('click');
                });
                showSelection();
            }
        });

        $selector.board.append($card);
    };

    var showSelection = function () {
        changeInfo('Deine Lotto zahlen: ' + selected.join(', '));
        $selector.board.find('.magic-card:not(.flip)').fadeOut(600, function () {
            setTimeout(function() {
                var delay = 0;
                $selector.board.find('.magic-card.flip').each(function (index, obj) {
                    delay += 200;
                    var number = selected.shift();

                    $(obj).find('> div:first-child').html(number).css({
                        'background-color': listColorSelected[index]
                    });
                    setTimeout(function() {
                        $(obj).toggleClass('flip');
                    }, delay);
                });
            }, 600);
        });
    };

    var resetBoard = function () {
        $selector.buttonRestart.click(function () {
            var $cards = $selector.board.find('.magic-card');
            $cards.removeClass('flip');
            setTimeout(function() {
                $selector.board.empty();
                initializeBoard();
            }, 500);
        });
    };


    var generateLottoArray = function () {
        var list = [];
        for(var i = 1; i <= 49; i++) {
            list.push(i);
        }
        return list;
    };

    var shuffleArray = function (array) {
        for (var j, x, i = array.length; i; j = parseInt(Math.random() * i), x = array[--i], array[i] = array[j], array[j] = x);
        return array;
    };

    var getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    var getOneRandomFromArray = function (array) {
        if (!Array.isArray(array) || array.length < 1) {
            return null;
        }
        var id = getRandomInt(0, array.length - 1);
        return array[id];
    };
};

jQuery(function ($) {
    var lotto = new window.CYB.Lotto();
    lotto.initialize();
});
